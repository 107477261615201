import wx from "weixin-js-sdk";
import share from "@/api/share"

/**
 * 通过config接口注入权限验证配置 接受url
 */
export function config(path) {

    return new Promise((res, rej) => {
        console.log(res + rej)
        let url = window.location.protocol + '//' + window.location.host + path
        //url = encodeURIComponent(url)

        share.getshare(url).then(res => {
            if (res.data.status == 1) {
                console.log(res);
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.data.data.appid, // 必填，公众号的唯一标识，填自己的！
                    timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                    nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.data.signature, // 必填，签名，见附录1
                    jsApiList: ["chooseWXPay"]
                });
            }
        });
    })
}

/**
 * 通过ready接口处理成功验证
 */
export function ready(jsApiList) {
    if (!jsApiList) return
    return new Promise((result, rej) => {
        wx.ready(function () {
            // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
            // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
            wx.checkJsApi({ // 判断当前客户端版本是否支持指定JS接口
                jsApiList: jsApiList,
                success: function (res) { // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
                    for (const key in res.checkResult) {
                        if (Object.prototype.hasOwnProperty.call(res.checkResult, key)) {
                            // if (res.checkResult.hasOwnProperty(key)) {
                            const element = res.checkResult[key];
                            if (element === true) {
                                result()
                            } else {
                                rej()
                            }
                        }
                    }

                },
                fail: function (res) { // 检测getNetworkType该功能失败时处理
                    console.log(res)
                    rej('fail' + res)
                    //Toast('fail' + res)
                }
            })
        })

        /* 处理失败验证 */
        wx.error(function (res) {
            // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
            rej('配置验证失败: ' + res.errMsg)
            //Toast('配置验证失败: ' + res.errMsg)
        })
    })
}