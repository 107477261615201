import base from './base'
import axios from '@/utils/http.js'
const lamp = {
    //获取供灯产品信息
    getlampprod() {
        return axios.get(`${base.url}/wxbizpage/Lamp/GetProductInfo`)
    },
    //生成供灯订单
    generateorder(productid, specid, name, phone, wish) {
        return axios.post(`${base.url}/wxbizpage/Lamp/GenerateLampOrder`, {
            productid: productid,
            specid: specid,
            name: name,
            phone: phone,
            wish: wish
        })
    },
    //查询订单是否支付
    ensure(orderno) {
        return axios.get(`${base.url}/wxbizpage/Lamp/EnsureLampOrder`, {
            params: {
                orderno: orderno
            }
        })
    },
}
export default lamp