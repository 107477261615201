import base from './base'
import axios from '@/utils/http.js'
const daypay ={
//生成乐捐订单
generateorder(price,name,phone,wish){
    return axios.post(`${base.url}/wxbizpage/daypay/GenerateWxOrder`,{
        price:price,
        name:name,
        phone:phone,
        wish:wish
    })
},
//查询订单是否支付
ensure(orderno){
    return axios.get(`${base.url}/wxbizpage/daypay/EnsureDayPayOrder`,{
        params:{
            orderno:orderno
        }
    })
},
//获取乐捐总数
gettotal(){
    return axios.get(`${base.url}/wxbizpage/daypay/GetTotal`)
}
}
export default daypay