import base from './base'
import axios from '@/utils/http.js'

const login={
//微信登陆
    wxlogin(id) {
        console.log(base.url)
        return axios.get(`${base.url}/Auth/wxh5login`, {

          params: {
            code: id
          }
        })
      },

}

export default login