import Vue from 'vue'
import VueRouter from 'vue-router'
import { config } from '@/utils/wxutils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/home.vue'),
    meta: {
      title: '石门寺'
    }
  },
  {
    path: '/login',
    component: () => import('../views/login.vue'),
    meta:{
      title:'登录'
    }
  },
  {
    path:'/article',
    component:()=>import('../views/article.vue'),
    meta:{
      title:'石门新闻'
    }
  },
  {
    path:'/detail',
    component:()=>import('../views/detail.vue'),
    meta:{
      title:'文章详情'
    }
  },
  {
    path: '/daypay',
    component: () => import('../views/daypay.vue'),
    meta: {
      title: '日行一善'
    }
  },
  {
    path: '/lamp',
    component: () => import('../views/lamp.vue'),
    meta: {
      title: '祈福供灯'
    }
  },
  {
    path: '/golive',
    component: () => import('../views/golive.vue'),
    meta: {
      title: '放生'
    }
  },
  {
    path: '/offers',
    component: () => import('../views/offers.vue'),
    meta: {
      title: '供养'
    }
  },
  {
    path: '/brand',
    component: () => import('../views/brand.vue'),
    meta: {
      title: '排位供奉'
    }
  },
  {
    path: '/register',
    component: () => import('../views/register.vue'),
    meta: {
      title: '佛事登记'
    }
  },
  {
    path: '/center',
    component: () => import('../views/center/index.vue'),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/foshi',
    component: () => import('../views/foshi.vue'),
    meta: {
      title: '佛事'
    }
  },
  //详情页面
  {
    path: '/center/brand',
    component: () => import('../views/center/brand.vue'),
    meta: {
      title: '牌位详情'
    }
  },
  {
    path: '/center/daypay',
    component: () => import('../views/center/daypay.vue'),
    meta: {
      title: '日行一善详情'
    }
  },
  {
    path: '/center/offers',
    component: () => import('../views/center/offers.vue'),
    meta: {
      title: '供养详情'
    }
  },
  {
    path: '/center/register',
    component: () => import('../views/center/register.vue'),
    meta: {
      title: '佛事登记详情'
    }
  },
  {
    path: '/center/golive',
    component: () => import('../views/center/golive.vue'),
    meta: {
      title: '放生详情'
    }
  },
  {
    path: '/center/lamp',
    component: () => import('../views/center/lamp.vue'),
    meta: {
      title: '供灯详情'
    }
  },
]
const router = new VueRouter({
  routes,
  mode: 'history'
})
router.beforeEach(function (to, from, next) {

  if (!localStorage.getItem("token")) {
    if (to.path !== '/login') {
      let encodeTo = encodeURIComponent(to.fullPath)
      return next('/login?return=' + encodeTo)
    }
    else {
      if (to.query.redirect) {
        let encode = encodeURIComponent(to.query.redirect)
        return next('/login?return=' + encode)
      }
    }
  }

  let u = navigator.userAgent;
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isiOS && to.path !== location.pathname) {
    // 此处不可使用location.replace
    location.assign(to.fullPath)
  } else {

    if (to.meta.title) {
      document.title = to.meta.title
    }

    next()

  }
  console.log(to)
},
  router.afterEach((to, from) => {
    console.log(from)
    let p = to.fullPath
    config(p)
  })
)
export default router