import base from './base'
import axios from '@/utils/http.js'
const golive = {
    //获取放生信息
    getgoliveprod() {
        return axios.get(`${base.url}/wxbizpage/GoLive/GetProductInfo`)
    },
    //生成放生订单
    generateorder(productid, specid, name, phone,wish,price) {
        return axios.post(`${base.url}/wxbizpage/GoLive/GenerateGoLiveOrder`, {
            productid: productid,
            specid: specid,
            name: name,
            phone: phone,
            wish: wish,
            price:price,
        })
    },
    //查询放生订单是否支付
    ensure(orderno) {
        return axios.get(`${base.url}/wxbizpage/GoLive/EnsureGoLiveOrder`, {
            params: {
                orderno: orderno
            }
        })
    },
}
export default golive