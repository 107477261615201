import base from './base'
import axios from '@/utils/http.js'
const offer = {
    //获取每日供养产品信息
    getofferprod() {
        return axios.get(`${base.url}/wxbizpage/Offer/GetProductInfo`)
    },
    //生成供养订单
    generateorder(productid, specid, name, phone, wish,price) {
        return axios.post(`${base.url}/wxbizpage/Offer/GenerateOfferOrder`, {
            productid: productid,
            specid: specid,
            name: name,
            phone: phone,
            wish: wish,
            price:price,
        })
    },
    //查询供养订单
    ensure(orderno) {
        return axios.get(`${base.url}/wxbizpage/Offer/EnsureOfferOrder`, {
            params: {
                orderno: orderno
            }
        })
    },
}
export default offer