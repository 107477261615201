import base from './base'; // 导入接口域名列表
import axios from '@/utils/http.js'; // 导入http中创建的axios实例

const share = {

    getshare(selfurl) {
        return axios.get(`${base.url}/appointment/Appointment/getwxshareconfig`, {
            params: {
                selfurl: selfurl
            }
        })
    }

}
export default share