import base from './base'
import axios from '@/utils/http.js'

const article = {
    //获取文章分类
    getarticleclass() {
        return axios.get(`${base.url}/wxbizpage/article/getcategory`)
    },
    //获取文章列表
    getarticlelist(categoryid, pageindex) {
        return axios.get(`${base.url}/wxbizpage/article/getarticlelist`, {
            params: {
                categoryid: categoryid,
                pageindex: pageindex,
            }
        })
    },
    //获取文章详情
    getarticledetails(id) {
        return axios.get(`${base.url}/wxbizpage/article/detail`, {
            params: {
                id: id,
            }
        })

    }

}
export default article