import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import api from './api'
import 'vant/lib/index.less';

import {
  Row, Col, Button, Loading, Form, Field, Image, Lazyload, RadioGroup,
  Radio, Cell, CellGroup, Icon, Overlay, DropdownMenu, DropdownItem, TabbarItem,
  Tabbar, Swipe, SwipeItem, Calendar, Tab, Tabs, List
} from 'vant'

Vue.use(Row).use(Col).use(Button).use(Loading).use(Form).use(Field).use(Image)
  .use(Lazyload).use(RadioGroup).use(Radio).use(Cell).use(CellGroup).use(Icon)
  .use(Overlay).use(DropdownMenu).use(DropdownItem).use(TabbarItem).use(Tabbar)
  .use(Swipe).use(SwipeItem).use(Calendar).use(Tab).use(Tabs).use(List)

Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
