import base from './base'
import axios from '@/utils/http.js'
const register = {
    //获取佛事信息
    getregisterprod() {
        return axios.get(`${base.url}/wxbizpage/Service/GetProductInfo`)
    },
    //生成佛事订单
    generateorder(productid, specid, name, phone, content, appointmenttime) {
        return axios.post(`${base.url}/wxbizpage/Service/GenerateServiceOrder`, {
            productid: productid,
            specid: specid,
            name: name,
            phone: phone,
            content: content,
            appointmenttime: appointmenttime

        })
    },
    //查询佛事订单是否支付
    ensure(orderno) {
        return axios.get(`${base.url}/wxbizpage/Service/EnsureServiceOrder`, {
            params: {
                orderno: orderno
            }
        })
    },
}
export default register