<template>
  <div id="app" style="app">
    <router-link to="/"></router-link>
    <router-view />
    <van-tabbar route active-color="#bb5548" inactive-color="#c89932">
      <van-tabbar-item replace to="/" icon="wap-home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/article" icon="description">新闻</van-tabbar-item>
      <van-tabbar-item replace to="/foshi" icon="flower-o">佛事</van-tabbar-item>
      <van-tabbar-item replace to="/center" icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
:root {
  font-size: 20px;
}
</style>
