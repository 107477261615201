import daypay from '@/api/daypay'
import login from '@/api/login'
import share from '@/api/share'
import lamp from '@/api/lamp'
import offer from '@/api/offer'
import golive from '@/api/golive'
import center from '@/api/center'
import brand from '@/api/brand'
import register from '@/api/register'
import article from '@/api/article'

export default {
    daypay,
    login,
    share,
    lamp,
    offer,
    golive,
    center,
    brand,
    register,
    article,
}