import base from './base'
import axios from '@/utils/http.js'
const brand = {
    //获取排位信息
    getbrandprod() {
        return axios.get(`${base.url}/wxbizpage/Brand/GetProductInfo`)
    },
    //生成排位订单
    generateorder(productid, specid, name, phone, address,name2) {
        return axios.post(`${base.url}/wxbizpage/Brand/GenerateBrandOrder`, {
            productid: productid,
            specid: specid,
            name: name,
            phone: phone,
            address: address,
            name2:name2,
        })
    },
    //查询排位订单是否支付
    ensure(orderno) {
        return axios.get(`${base.url}/wxbizpage/Brand/EnsureBrandOrder`, {
            params: {
                orderno: orderno
            }
        })
    },
}
export default brand