import base from './base'
import axios from '@/utils/http.js'
const center = {
    //获取头像昵称
    getavatar() {
        return axios.get(`${base.url}/wxbizpage/Member/UserInfo`)
    },
    //查询排位详情
    getbrand(currentPage) {
        return axios.get(`${base.url}/wxbizpage/Brand/GetOrderHistory`, {
            params: {
                pageindex: currentPage
            }
        })
    },
    //查询日行一善详情
    getdaypay(currentPage) {
        return axios.get(`${base.url}/wxbizpage/DayPay/GetOrderHistory`, {
            params: {
                pageindex: currentPage
            }
        })
    },
    //查询佛事登记详情
    getregister(currentPage) {
        return axios.get(`${base.url}/wxbizpage/Service/GetOrderHistory`, {
            params: {
                pageindex: currentPage
            }
        })
    },
    //查询供灯详情
    getlamp(currentPage, productid) {
        return axios.get(`${base.url}/wxbizpage/Offer/GetOrderHistory`, {
            params: {
                pageindex: currentPage,
                productid: productid
            }
        })
    },
    //查询放生详情
    getgolive(currentPage, productid) {
        return axios.get(`${base.url}/wxbizpage/Offer/GetOrderHistory`, {
            params: {
                pageindex: currentPage,
                productid: productid
            }
        })
    },
    //查询供养详情
    getoffers(currentPage, productid) {
        return axios.get(`${base.url}/wxbizpage/Offer/GetOrderHistory`, {
            params: {
                pageindex: currentPage,
                productid: productid
            }
        })
    },
}
export default center